<template>
  <div class="home">
    <h3 class="common-title">欢迎词</h3>
    <div class="clearfix pb20">
      <div class="fr">
        <el-button type="primary" @click="newWelcome">追加</el-button>
      </div>
    </div>
    <div class="home-left">
      <el-container>
        <el-main>
          <Empty v-if="welcomeList.length === 0"></Empty>
          <el-table
            :data="welcomeList"
            class="home-list"
            style="width: 100%"
            height="calc(100vh - 600px)"
            v-else>
            <el-table-column
              label="ID"
              prop="id"
              width="80">
            </el-table-column>
            <el-table-column
              prop="message"
              label="消息">
            </el-table-column>
            <el-table-column
              prop="start_date"
              label="开始日"
              width="120">
            </el-table-column>
            <el-table-column
              prop="end_date"
              label="结束日"
              width="120">
            </el-table-column>
            <el-table-column
              prop="is_default"
              label="默认"
              width="60">
              <template #default="scope">
                <el-icon v-if="scope.row.is_default" color="green">
                  <CircleCheckFilled/>
                </el-icon>
                <el-icon v-else color="red">
                  <CircleCloseFilled/>
                </el-icon>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="120px">
              <template #default="scope">
                <el-button
                  type="primary"
                  link
                  @click="editWelcome(scope.row)">
                  编辑
                </el-button>

                <el-button
                  type="danger"
                  link
                  @click="deleteWelcome(scope.row)"
                  v-if="scope.row.is_default !== true">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-main>
      </el-container>
    </div>


    <el-dialog v-model="welcomeFormRef" :title="welcomeForm.id === null ? '新增':'编辑'" width="60%" destroy-on-close
               draggable>
      <span v-loading="loading">
        <el-form
          ref="welcomeRuleFormRef"
          :model="welcomeForm"
          :rules="welcomeRules"
          label-width="120px"
          class="home-form"
        >
        <el-form-item label="欢迎信息" prop="message">
          <el-input type="textarea" rows="3" v-model="welcomeForm.message"></el-input>
        </el-form-item>
        <div v-for="config in merchantServerLanguages">
          <el-form-item :label="config.name + '欢迎信息'" :prop="config.prefix + '_message'">
            <el-input type="textarea" rows="3"
                      v-model="welcomeForm.multilingual_data[config.prefix + '_message']"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="开始日期" prop="start_date">
          <el-date-picker
            v-model="welcomeForm.start_date"
            type="date"
            placeholder="选择日期"
            value-format="YYYY-MM-DD"
            :disabled=welcomeForm.is_default>
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束日期" prop="end_date">
          <el-date-picker
            v-model="welcomeForm.end_date"
            type="date"
            placeholder="选择日期"
            value-format="YYYY-MM-DD"
            :disabled=welcomeForm.is_default>
          </el-date-picker>
        </el-form-item>
        </el-form></span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="welcomeFormRef = false">取消</el-button>
          <el-button type="primary" @click="createWelcome">
            {{ welcomeForm.id === null ? '新增' : '确定' }}
          </el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import { reactive, ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { authApi } from '@/api'
import { computed, onMounted } from "@vue/runtime-core";
import Empty from "@/components/Empty.vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
// @ is an alias to /src

export default {
  name: 'Home',
  components: { Empty },
  setup() {
    const route = useRoute()
    const router = useRouter()

    // merchant form

    const merchant = computed(() => {
      return store.getters.merchant
    })

    const merchantServerLanguages = computed(() => {
      return merchant.value.server_languages.filter(item => item.is_active)
    })

    const loading = ref(false)

    const serverLanguages = ref([])

    const welcomeList = ref([])
    const getWelcomeList = (reset) => {
      // chatHistory.value = []
      loading.value = true
      authApi.getWelcomeList(merchant.value.id).then(res => {
        welcomeList.value = res.data
      }).finally(() => {
        loading.value = false
      })
    }

    const welcomeFormRaw = reactive({
      id: null,
      message: null,
      start_date: new Date(),
      end_date: null,
      is_default: false,
      multilingual_data: {},
    })
    const welcomeForm = reactive(JSON.parse(JSON.stringify(welcomeFormRaw)))
    const welcomeFormRef = ref(false)

    const welcomeRuleFormRef = ref(null)

    const welcomeRules = reactive({
      message: [
        { required: true, message: '请输入欢迎信息', trigger: 'blur' },
      ],
      start_date: [
        { required: true, message: '请选择开始日期', trigger: 'blur' },
      ],
      end_date: [
        { required: true, message: '请选择结束日期', trigger: 'blur' },
      ],
    })

    const newWelcome = () => {
      Object.keys(welcomeFormRaw).forEach(key => {
        welcomeForm[key] = welcomeFormRaw[key]
      })
      welcomeFormRef.value = true
    }
    const editWelcome = (item) => {
      console.log(item)
      Object.keys(welcomeFormRaw).forEach(key => {
        welcomeForm[key] = item[key]
      })
      welcomeForm.message = item.message
      welcomeFormRef.value = true
    }
    const deleteWelcome = (item) => {
      ElMessageBox.confirm(
        '确定要删除吗?',
        '删除欢迎信息',
        {
          confirmButtonText: '删除',
          cancelButtonText: '取消',
          type: 'error',
        }
      ).then(() => {
        console.log(item.id)
        loading.value = true
        authApi.deleteWelcome(merchant.value.id, item.id).then(res => {
          console.log(res)
          ElMessage({
            message: '删除成功',
            type: 'success',
          })
          getWelcomeList()
        }).finally(() => {
          loading.value = false
        })
      })

    }
    const createWelcome = () => {
      welcomeRuleFormRef.value.validate((valid) => {
        if (valid) {
          let verify_welcome = welcomeList.value.find(item => {
            const result = item.end_date >= welcomeForm.start_date && item.start_date <= welcomeForm.end_date && item.is_default === false;
            return welcomeForm.id !== null ? (result && item.id !== welcomeForm.id) : result;
          })
          if (verify_welcome !== undefined && welcomeForm.is_default === false) {
            ElMessage({
              message: '该时间段已存在欢迎信息',
              type: 'error',
            })
            return
          }

          loading.value = true
          if (welcomeForm.id === null) {
            authApi.createWelcome(merchant.value.id, welcomeForm).then(res => {
              console.log(res)
              ElMessage({
                message: '创建成功',
                type: 'success',
              })
              welcomeFormRef.value = false
              getWelcomeList()
            }).finally(() => {
              loading.value = false
            })
          } else {
            authApi.updateWelcome(merchant.value.id, welcomeForm.id, welcomeForm).then(res => {
              console.log(res)
              ElMessage({
                message: '编辑成功',
                type: 'success',
              })
              welcomeFormRef.value = false
              getWelcomeList()
            }).finally(() => {
              loading.value = false
            })
          }
        }
      })
    }

    onMounted(() => {
      getWelcomeList()
    })
    return {
      loading,

      merchant,
      merchantServerLanguages,

      welcomeList,
      newWelcome,
      createWelcome,
      welcomeForm,
      welcomeFormRef,
      welcomeRuleFormRef,
      welcomeRules,
      editWelcome,
      deleteWelcome,

      // 多语言
      serverLanguages,
    }
  }
}
</script>
<style scoped>
.preview-cell {
  white-space: pre-line;
}

.document-tabs .el-tabs__item {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}
</style>
